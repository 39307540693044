<template>
  <div>
    <div v-if="!param.isApprContent" class="text-right">
      <q-btn-group outline>
        <!-- QR 코드 -->
        <c-qr-btn v-if="editable && educationInfo.documentStatusCd && educationInfo.documentStatusCd !== 'ESC000001'" :mobileUrl="'/sop/edu/result/educationResult?eduEducationId='+param.eduEducationId" ></c-qr-btn>
        <!-- 완료 -->
        <!-- <c-btn v-show="editable&&!disabled" :showLoading="false" label="LBLCOMPLETE" icon="check"  @btnClicked="resultComplete" /> -->
        <c-appr-btn 
          ref="appr-btn"
          :editable="editable"
          :approvalInfo="approvalInfo"
          @beforeApprAction="approvalEducationInfo"
          @callbackApprAction="approvalEducationInfoCallback"
          @requestAfterAction="getDetail"
        />
        <!-- 대상자 연명부 -->
        <c-btn v-show="editable" label="LBL0002902" icon="print" @btnClicked="btnResultUsers" />
      </q-btn-group>
    </div>
    <component
      :is="component"
      :param.sync="param"
      :educationInfo.sync="educationInfo"
      :planUpdateBtnData.sync="planUpdateBtnData"
      @setData="setData"
    />
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        searchResult: {
          flag: '',
          isSearch: false,
        },
        isApprContent: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
        eduEvalUserList: [],
        deleteEduEvalUserList: [],
        
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,

        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_COMPLETE_LIST',
        taskKey: '',
      },
      planUpdateBtnData: {
        title: 'LBLPLAN',
        flag: false,
        research: '',
        planResearch: '',
      },
      isApproval: false,
      approvalUrl: '',
      completeUrl: '',
      editable: true,
      splitter: 5,
      tab: 'educationResultInfo',
      printResultUserUrl: '',
      getUrl: '',
      component: () => import(`${'./educationComplete.vue'}`),
    };
  },
  computed: {
    // tabHeight() {
    //   let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
    //   numHeight = numHeight - 40
    //   return numHeight + 'px';
    // },
    // tabItems() {
    //   return [
    //     // 기본정보
    //     { name: 'educationResultInfo', icon: 'edit', label: '교육평가', component: () => import(`${'./educationResultInfo.vue'}`) },
    //     // 교육이수
    //     { name: 'educationComplete', icon: 'how_to_reg', label: 'LBL0002903', component: () => import(`${'./educationComplete.vue'}`) },
    //   ]
    // },
    disabled() {
      return this.educationInfo.documentStatusCd === 'ESC000010'
        || this.educationInfo.approvalStatusCd === 'ASC0000001'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.educationInfo.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.educationInfo.approvalStatusCd, 
        apprEditable: this.educationInfo.documentStatusCd !== 'ESC000010', // 결재버튼 활성화 기준
        param: this.educationInfo.eduAttendeeList, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000023', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          eduEducationId: this.educationInfo.eduEducationId,
          isApprContent: true
        },
        approvalRequestName: `[${this.educationInfo.educationCourseName}] ${this.educationInfo.educationRangeFlag === 'Y' ? this.educationInfo.educationStartDate + ' ~ ' + this.educationInfo.educationEndDate : this.educationInfo.educationStartDate} 교육 결과보고`, // 결재요청명 (문서 title)
        approvalConnId: this.educationInfo.eduEducationId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
  },
  watch: {
    'param.planUpdateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.redirectUrl = selectConfig.com.mobileHostname.url;
      this.completeUrl = transactionConfig.sop.edu.result.complete.url;
      this.approvalUrl = transactionConfig.sop.edu.plan.insert.targetUser.url;
      this.printResultUserUrl = selectConfig.sop.edu.result.print.targetUser.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.educationInfo.eduEducationId)
        },);
      }
    },
    setData(data) {
      this.param.eduEducationId = data;
      this.getDetail();
    },
    resultComplete() {
      let flag = false;
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSG0000868', // 결과완료하시겠습니까?(저장과 함께 처리됩니다.)
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.educationInfo.documentStatusCd = 'ESC000010'
            this.educationInfo.regUserId = this.$store.getters.user.userId
            this.educationInfo.chgUserId = this.$store.getters.user.userId
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.educationInfo;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    btnResultUsers() {
      let thisVue = this;
      this.$http.url = this.printResultUserUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: this.param.eduEducationId
      }
      this.$http.request(
        _result => {
          let fileOrgNm = this.educationInfo.educationName + ' 연명부.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    approvalEducationInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.educationInfo.chgUserId = this.$store.getters.user.userId;

          this.isApproval = !this.isApproval
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalEducationInfoCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>
